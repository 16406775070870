import React from 'react';
import PropTypes from 'prop-types';
import RebelLayout from '../components/layouts/RebelLayout';
import { graphql, Link } from 'gatsby';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import SearchEngineOptimisation from '../components/SearchEngineOptimisation';
import RebelDetailBanner from '../components/RebelDetailBanner';
import RebelSlider from '../components/RebelSlider';
import { SocialIcon } from 'react-social-icons';

const RebelDetail = ({ data }) => {
	const { html } = data.markdownRemark;
	const { 
		title,
		youtubeVideoID,
		youtubeTitle, 
		heading,
		byline,
		twitterLink,
		linkedinLink
	} = data.markdownRemark.frontmatter;

	
	return (
		<RebelLayout>
			<SearchEngineOptimisation title={`Tobias Rebels - ${title}`}  />
			<main id="content" role="main">
				<div className="isolate bg-black border-t-8 border-pink">
					<div className="container w-full h-auto relative overflow-hidden">						
            <RebelDetailBanner 
							youtubeVideoID={youtubeVideoID} 
							youtubeTitle={youtubeTitle} />
          </div>
				</div>

				<div className="isolate bg-black lg:pt-12 md:pt-8 pt-6 border-t-8 border-pink">
					<RebelSlider />
				</div>

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<Link to="/inspiration" className="md:-ml-12 -ml-8 uppercase text-gray-500 font-bold text-sm pb-1 underline ">
								<ChevronLeftIcon className="md:w-12 md:h-12 h-8 w-8 inline md:pr-1 pr-0 text-pink" />Inspiration
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<h1 className="uppercase lg:text-6xl md:text-5xl text-4xl text-center font-bold">{ heading }</h1>
							</div>
						</div>
					</div>
				</div>



				<div className="isolate bg-white lg:pt-4 md:pt-2 pt-1">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<h2 className="lg:text-2xl md:text-xl text-lg text-center">{ byline }</h2>
							</div>
						</div>
					</div>
				</div>

				<div className="isolate bg-white lg:pt-4 md:pt-2 pt-1">
					<div className="container">
						<div className="grid grid-cols-1">
							<div className="text-center">
								<SocialIcon url={linkedinLink} target="_blank" fgColor="#000" bgColor="transparent" className="md:w-10 md:h-10 w-8 h-8 pr-8" />
								<SocialIcon url={twitterLink} target="_blank" fgColor="#000" bgColor="transparent" className="md:w-10 md:h-10 w-8 h-8 pr-8" />
							</div>
						</div>
					</div>
				</div>
				

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<div className="rebel-body" dangerouslySetInnerHTML={{ __html: html }} />
							</div>
						</div>
					</div>
				</div>

			</main>
		</RebelLayout>
	)
}

export default RebelDetail;

RebelDetail.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export const rebelDetailPageQuery = graphql`
query rebelDetailPage($slug: String) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    html
    frontmatter {
      title
			youtubeVideoID
			youtubeTitle
      heading
			byline
			twitterLink
			linkedinLink
    }
  }
}
`