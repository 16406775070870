import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

 const RebelCard = ({image, heading, indexPageByLine, youtubeLength, comingSoon})  => {

	const passedImg = image.substring((image.lastIndexOf('/')+1)) || null;

	// const passedImg = 'ash.jpeg';

	let imgObj;

	const rebelCardsData = useStaticQuery(graphql`
	query rebelCardsData {
		allFile(filter: {sourceInstanceName: {eq: "rebelThmbs"}}) {
			edges {
				node {
					childImageSharp {
						gatsbyImageData(
							width: 800, 
							placeholder: BLURRED, 
							formats: [AUTO, WEBP, AVIF]
						)
					}
					relativePath
				}
			}
		}
	}
  `);

	for (let i = 0; i < rebelCardsData.allFile.edges.length; i++) {
		if (rebelCardsData.allFile.edges[i].node.relativePath === passedImg) {
			imgObj = rebelCardsData.allFile.edges[i].node;
			break;
		}
	}

	const backgroundImg = getImage(imgObj);
	const bgImage = convertToBgImage(backgroundImg);

	return (
		<div className="xl:mx-12 lg:mx-8 sm:mx-4 mx-2 pb-8 h-auto">
			<div className="shadow-xl">
				<BackgroundImage className={`border-b-4 border-pink relative bg-top bg-center bg-cover bg-top overflow-hidden xl:h-60 h-52`} Tag="div" {...bgImage}>
					<div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 z-0"></div>
					<div className="grid grid-col-1 z-10 absolute left-0 top-0 w-full h-full px-4 py-2">
						{ comingSoon &&
						<div className="self-start text-center">
							<span className="xl:text-3xl md:text-2xl sm:text-xl text-lg text-white uppercase font-bold">Coming Soon</span>
						</div>
						}
						<div className="self-end text-left">
							<span className="text-pink lg:text-base md:text-sm text-xs font-bold uppercase">{indexPageByLine}</span>
							<h3 className="text-white text-opacity-50 font-bold lg:text-3xl md:text-2xl sm:text-xl text-lg uppercase">{heading}</h3>
						</div>
					
						{youtubeLength && 
							<span className="absolute bottom-2 right-2 bg-black text-white w-auto px-2">{youtubeLength}</span>
						}
					</div>
				</BackgroundImage>
			</div>
		</div>
	)
}

export default RebelCard;